import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { PublicKey } from '@solana/web3.js';
import { BlockchainPlatforms } from "../model/payments";

export function noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.value.trim() === '') {
    return { 'whitespace': true };
  }
  return null;
}

export function platformValidator(platform: BlockchainPlatforms): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!platform) return null;
    const value = control.value;

    switch (platform) {
      case BlockchainPlatforms.TRC20:
        return /^(T[A-Za-z1-9]{33})$/.test(value) ? null : { invalidFormat: true };
      case BlockchainPlatforms.ERC20:
        return /^(0x[a-fA-F0-9]{40})$/.test(value) ? null : { invalidFormat: true };
      case BlockchainPlatforms.Ton:
        return /^[A-Za-z0-9_-]{48}$/.test(value) ? null : { invalidFormat: true };
      // case BlockchainPlatforms.Solana:
      //   return isValidSolanaAddress(value) ? null : { invalidFormat: true };
      default:
        return null;
    }
  };
}

function  isValidSolanaAddress(address: string): boolean {
  try {
    // let y = new PublicKey(address);
    return true;
  } catch (e) {
    return false;
  }
}
export function passwordMatchValidator(formGroup: AbstractControl): ValidationErrors | null {
  const { value: password } = formGroup.get('password');
  const { value: confirmPassword } = formGroup.get('confirmPassword');
  return password === confirmPassword ? null : { passwordNotMatch: true };
};


export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value: string = control.value;
    if (value?.length < 6) {
      return { 'formatError': true };
    }

    if (!/\d/.test(value)) {
      return { 'formatError': true };
    }

    if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
      return { 'formatError': true };
    }

    return null;
  };
}
